import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import {
  CardList,
  UpcomingEvents,
  FeatureSlider,
  Title,
  Button,
} from 'components';
import { Layout, Container } from 'layouts';
import theme from '../../config/theme';
import config from '../../config/site';
import i18n from '../i18n/i18n';

const EventContainer = styled.div`
  background: ${theme.colors.white.light};
  padding: 6.5rem 0;
  margin-top: 4rem;
  text-align: center;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 4rem 0;
  }
`;

const Index = ({
  data,
  pageContext: {
    locale,
    defaultSlug,
    supportedLangs,
    featuredJobPathList,
    insertIndexForFeaturedJobs,
  }
}) => {

  i18n.changeLanguage(locale)
  /**
   * FeaturedPostが0件の場合
   * FeatureSliderタグで何も表示しないので、
   * ここでは件数チェックはしない
   */
  const featuredPostNodes = data.featuredPosts.edges.map(edge => edge.node)
  const featuredJobNodes = data.featuredJobs.edges.map(edge => edge.node)
  const sortedFeaturedJobPathList = featuredJobPathList.map(path => 
    featuredJobNodes.find(node => node.frontmatter.path === path)
  )


  /**
   * ロケールごとの記事が0件でも
   * gatsby-node.jsでページを生成しているので
   * 存在チェックをする
   */
  const Content = data.allPost && data.allPost.edges.length > 0
    ? <CardList
        list={data.allPost.edges}
        featuredJobNodes={sortedFeaturedJobPathList}
        insertIndexForFeaturedJobs={insertIndexForFeaturedJobs}
      />
    : <div>No Article</div>

  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <Helmet title={config[locale].title} />
      <FeatureSlider list={featuredPostNodes} />
      <Container type="big">
        {Content}
      </Container>
      <EventContainer>
        <Container type="big">
          <Title title={i18n.t("upcomingEventAreaTitle")} />
          <UpcomingEvents />
          <Button title={i18n.t("button.seeAllEvents")} to="/events" />
        </Container>
      </EventContainer>
    </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};


/**
 * featuredJobs、featuredPostsは、
 * クエリ結果をfeaturedJobPathList(文字列配列)で指定した順番通りにソートし直すため
 * クエリではソートはしない
 */
export const query = graphql`
  query(
    $featuredPostPathList: [String],
    $featuredJobPathList: [String],
    $locale: String!
  ) {


    featuredJobs: allMarkdownRemark(
      filter: { frontmatter: { path: { in: $featuredJobPathList } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            titleJp
            employmentType
            country
            department
          }
        }
      }
    }


    featuredPosts: allMarkdownRemark(
      filter: {
        frontmatter: { path: { in: $featuredPostPathList } }
        fields: {
          collection: { eq: "posts" }
          localePath: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            localePath
            slug
            defaultSlug
          }
          frontmatter {
            title
            path
            tags
            date
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  maxHeight: 560
                  quality: 50
                  traceSVG: { color: "#B6BCCF" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }


    allPost: allMarkdownRemark(
      filter: {
        fields: {
          collection: { eq: "posts" }
          localePath: { eq: $locale }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            localePath
            slug
            defaultSlug
          }
          frontmatter {
            title
            path
            tags
            date
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 640
                  maxHeight: 384
                  quality: 50
                  traceSVG: { color: "#B6BCCF" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
